<template>
    <section>
        <loading-indicator />
        היי! כרגע אין מה לראות כאן
    </section>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import store from '@/store';

export default defineComponent({
    beforeRouteEnter(to, from, next){
        if(store.state.firebase?.user)
            next('/admin');
        else
            next();
    }
});
</script>

<style lang="scss" scoped>
section {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    font-size: 1.2rem;
    gap: 2rem;
    color: #999;
}
</style>